import React from "react";
import { Table, Form, FormGroup, Input, Label } from 'reactstrap';
import TruckyAPIClient from '../../services/TruckyAPIClient';
import openSocket from 'socket.io-client';
import config from '../../config';
import Button from '../../components/CustomButtons/Button';
import { connect } from "react-redux";
import { globalMapStateToProps } from "../../redux/reducers";
import { globalMapDispatchToProps } from "../../redux/actions";
import SocketClient from "../../services/SocketClient";
require('linqjs');

class RealOpsPanel extends React.Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.api = new TruckyAPIClient();

        this.state = {
            messages: [],
            servers: []
        }
    }

    componentDidMount() {
        var secure = !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

        this.api.getRealOpsMessages().then((data) => {
            this.setState({ messages: data.response })
        });

        this.io = openSocket(config.SOCKET_IO_REAL_OPS, { transports: ['websocket'], secure: secure });

        this.io.on('connect', () => {
            console.log('Connected to real time websocket');
            this.io.emit('aknowlegde', {});
        });

        this.io.on('message', (message) => {
            if (message.type == 'RealOpsMessage') {
                this.setState({ messages: [message.data, ...this.state.messages] });
            }
        });

        this.api.servers().then(response => {
            this.setState({ servers: response.response.servers });
        });
    }

    async sendMessage() {

        var formData = new FormData();

        var color = 'info';
        var icon = document.getElementById('icon').value;

        if (icon != '')
            color = this.getIcons().first((m) => { return m.key == document.getElementById('icon').value }).color

        var data = {
            message: document.getElementById('message').value,
            icon: icon,
            color: color,
            author: this.props.user.username,
            server: document.getElementById('server').value
        };

        formData.append('file', document.getElementById('file').files[0]);
        formData.append('data', JSON.stringify(data));

        var messageSent = await this.api.saveRealOpsMessage(formData);

        //this.io.broadcast('message', { type: 'RealOpsMessage', data: messageSent });
        SocketClient.sendMessageToRoom('bot', `server-${data.server}`, { type: 'RealOpsMessage', data: messageSent });
    }

    getIcons() {
        var icons = [
            { key: 'accident', label: 'Accident', color: 'warning' },
            { key: 'incident', label: 'Incident', color: 'warning' },
            { key: 'queue', label: 'Queue', color: 'warning' },
            { key: 'rworks', label: 'Roadworks', color: 'warning' },
        ];

        return icons;
    }

    getIconsSelect() {
        return this.getIcons().map((m) => {
            return (
                <option value={m.key}>{m.label}</option>
            );
        });
    }

    getIconsLegend() {
        return this.getIcons().map((m) => {
            return (
                <div>
                    <img src={"/realops/" + m.key + ".png"} /> {m.label}<br />
                </div>
            );
        });
    }

    clearAllMessages() {
        this.api.deleteAllRealOpsMessages();
        this.setState({ messages: [] });
    }

    render() {
        return (<div>
            <div>
                <Button onClick={() => this.clearAllMessages()} color="primary">Clear all</Button>
                <Form encType="multipart/form-data">
                    <FormGroup>
                        <Label for="server">Server</Label>
                        <Input type="select" name="server" id="server" className="form-control">
                            {this.state.servers.map(m => {
                                return (
                                    <option value={m.id}>{m.game} - {m.name} ({m.id})</option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="message">Message</Label>
                        <Input name="message" id="message" className="form-control" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="icon">Icon</Label>
                        <Input type="select" name="icon" id="icon" className="form-control">
                            <option value="">No Icon</option>
                            {this.getIconsSelect()}
                        </Input>
                        <div className="mt-3 mb-3">
                            Legend:
                            <br />
                            {this.getIconsLegend()}
                        </div>
                        <Label for="file">Attachment</Label>
                        <Input type="file" name="file" id="file" className="form-control" />
                    </FormGroup>
                </Form>
                <Button color="primary" onClick={() => this.sendMessage()}>Send</Button>
            </div>
            <div className="mt-5">
                {this.state.messages.map((m) => {
                    return (
                        <div className="row">
                            <div className="col-sm-12">
                                {m.message}
                                <br />
                                {m.attachment != '' &&
                                    <img src={m.attachment} />
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>)
    }
}

export default connect(globalMapStateToProps, globalMapDispatchToProps)(RealOpsPanel);