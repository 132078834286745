var dev = {
    REACT_APP_DISCORD_ADMIN_AUTH: "http://localhost:5000/discord-admin/auth",
    SOCKET_IO_REAL_OPS: "http://localhost:5001/bot",
    SOCKET_IO_API_URL: "http://localhost:5002",
    AUTH_PAGE_URL: 'https://vtchub.test/authentication/login/steam?returnUrl='
};

var prod = {
    REACT_APP_DISCORD_ADMIN_AUTH: "https://api.truckyapp.com/discord-admin/auth",
    SOCKET_IO_REAL_OPS: "wss://realtime.truckyapp.com/bot",
    SOCKET_IO_API_URL: "https://realtime-api.truckyapp.com",
    AUTH_PAGE_URL: 'https://e.truckyapp.com/authentication/login/steam?returnUrl='
};

var config = prod;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    config = dev;
}

export default config;