import cookie from "react-cookies";

/* eslint-disable */
/**
 * Trucky API client class
 * @class
 */
class TruckyAPIClient {
  /**
   * Creates an instance of TruckyAPIClient.
   *
   * @memberOf TruckyAPIClient
   */
  constructor(token) {
    /** @access private */
    this.config = {
      serviceUrl: "https://api.truckyapp.com"
    };

    /*if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      this.config.serviceUrl = "http://localhost:5000";
    }*/

    this.setToken(token);
  }

  /**
   *
   *
   * @access private
   * @param {string} url
   * @ignore
   * @async
   * @returns {Promise}
   *
   * @memberOf TruckyAPIClient
   */
  async executeRequest(url, method = "GET", payload = null, auth = false) {
    try {
      var myHeaders = new Headers();
      //myHeaders.set('user-agent', 'TruckyApp');
      //myHeaders.set("x-platform", 'Web');

      var myInit = {
        method: method,
        headers: myHeaders,
        mode: "cors",
        cache: "no-cache"
      };

      if (method == 'FORM')
      {
        myInit.method = 'POST';
        myInit.body = payload;        
      }

      if (auth) {
        if (this.token == "" || this.token == undefined || this.token == null) {
          this.token = cookie.load("trucky-admin-auth");
        }

        myInit.headers.set("x-access-token", this.token);
      }

      if (method == "POST") {
        //console.warn(payload);

        myInit.headers.set("Accept", "application/json, text/plain, */*");
        myInit.headers.set("Content-Type", "application/json");

        myInit.body = JSON.stringify(payload);
      }

      //console.log(this.config.serviceUrl + url);

      var response = await fetch(this.config.serviceUrl + url, myInit);
      var json = await response.json();

      return json;
    } catch (error) {
      alert("Network error");

      console.log("TruckyServices API request: " + error.message);

      return {
        error: true,
        response: null
      };
    }
  }

  setToken(token) {
    this.token = token;
  }

  async checkAuth() {
    var response = await this.executeRequest(
      "/webapp/admin/auth",
      "POST",
      {},
      true
    );
    return response;
  }

  async botStats() {
    var response = await this.executeRequest("/bot/stats", "GET", {}, false);
    return response;
  }

  async appStats() {
    var response = {};

    response.android = await this.executeRequest(
      "/stats/app/sales?market=google-play"
    );
    response.ios = await this.executeRequest("/stats/app/sales?market=ios");

    return response;
  }

  /**
   * Resolve Steam Vanity Url returning Steam player data if found
   *
   * @param {string} username Steam user vanity url
   * @async
   * @returns {Promise<TruckyAPIResponse>}
   *
   * @memberOf TruckyAPIClient
   */
  async resolveVanityUrl(username) {
    var response = await this.executeRequest(
      "/v2/steam/resolveVanityUrl?username=" + username
    );
    return response;
  }

  /**
   * Returns Steam Player data if found
   *
   * @param {string} steamid Steam User ID
   * @async
   * @returns {Promise<TruckyAPIResponse>}
   *
   * @memberOf TruckyServices
   */
  async getPlayerSummaries(steamid) {
    var response = await this.executeRequest(
      "/v2/steam/getPlayerSummaries?steamid=" + steamid
    );
    return response;
  }

  /**
   * Get ETS2Map Points of interest
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @async
   * @memberof TruckyAPIClient
   */
  async pois() {
    var response = await this.executeRequest("/v2/map/pois");
    return response;
  }

  /**
   * Check TruckersMP player online status
   *
   * @param {number} playerID TruckersMP User ID
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async isOnline(playerID) {
    var response = await this.executeRequest(
      "/v3/map/online?playerID=" + playerID
    );
    return response;
  }

  /**
   * Get Steam Friends
   *
   * @param {string} steamID Steam User ID
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async getFriends(steamID) {
    var response = await this.executeRequest(
      "/v2/steam/getFriendsData?onlineState=true&steamid=" + steamID
    );
    return response;
  }

  /**
   * Get TruckersMP news feed from Steam RSS
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async news() {
    var response = await this.executeRequest("/v3/rss/truckersMP");
    return response;
  }

  /**
   * Get ETS2 News feed from Steam RSS
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async ets2News() {
    var response = await this.executeRequest("/v3/rss/ets2");
    return response;
  }

  /**
   * Get ATS News feed from Steam RSS
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async atsNews() {
    var response = await this.executeRequest("/v3/rss/ats");
    return response;
  }

  /**
   * Get TruckersMP current update info and current plugin version
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async update_info() {
    var response = await this.executeRequest("/v2/truckersmp/update_info");
    return response;
  }

  /**
   * Get events and meetup from ETS2.com and truckers.events
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async events() {
    var response = await this.executeRequest("/v2/events/upcoming");
    return response;
  }

  async running_events() {
    var response = await this.executeRequest("/v2/events/running");
    return response;
  }

  /**
   * Get TruckersMP servers
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async servers() {
    var response = await this.executeRequest("/v2/truckersmp/servers");
    return response;
  }

  /**
   * Get TruckersMP game version info
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async game_version() {
    var response = await this.executeRequest("/v2/truckersmp/version");
    return response;
  }

  /**
   * Get TruckersMP game time
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async game_time() {
    var response = await this.executeRequest("/v2/truckersmp/time");
    return response;
  }

  /**
   * Get TruckersMP rules
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async rules() {
    var response = await this.executeRequest("/v2/truckersmp/rules");
    return response;
  }

  /**
   * Get TruckersMP player data
   *
   * @param {number} id TruckersMP User ID
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async player(id) {
    var response = await this.executeRequest(
      "/v2/truckersmp/player?playerID=" + id
    );
    return response;
  }

  /**
   * Get TruckersMP player bans data
   *
   * @param {number} id TruckersMP User ID
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async bans(id) {
    var response = await this.executeRequest(
      "/v2/truckersmp/bans?playerID=" + id
    );
    return response;
  }

  /**
   * Search player by Steam ID, Steam Username or TruckersMP ID based on searchType parameter
   *
   * @param {string} searchTerm Search Term, can be TruckersMP User ID, Steam User ID or Steam Vanity url
   * @param {string} searchType Can be "steamid", "truckersmpid", "steamusername"
   * @async
   * @returns {Promise<TruckyAPIResponse>}
   *
   * @memberOf TruckyAPIClient
   */
  async searchPlayer(searchTerm, searchType) {
    var playerInfo = {
      found: false,
      steamProfileInfo: null,
      truckersMPProfileInfo: null,
      bans: []
    };

    //console.warn(searchType);

    switch (searchType) {
      case "steamusername":
        var steamResponse = await this.resolveVanityUrl(searchTerm);

        if (steamResponse.response.found) {
          playerInfo.steamProfileInfo = steamResponse.response.playerInfo;
          var apiResponse = await this.player(steamResponse.response.steamID);

          if (!apiResponse.error) {
            playerInfo.found = true;
            playerInfo.truckersMPProfileInfo = apiResponse.response.response;
            var bansResponse = await this.bans(
              playerInfo.truckersMPProfileInfo.id
            );
            playerInfo.bans = bansResponse.response;
            // playerInfo.onlineStatus = await
            // truckyApi.isOnline(playerInfo.truckersMPProfileInfo.id);
          } else {
            playerInfo.found = false;
          }
        }

        break;
      case "steamid":
      case "truckersmpid":
        var apiResponse = await this.player(searchTerm);

        if (!apiResponse.response.error) {
          playerInfo.truckersMPProfileInfo = apiResponse.response.response;
          var steamProfileInfo = await this.getPlayerSummaries(
            playerInfo.truckersMPProfileInfo.steamID64
          );
          playerInfo.steamProfileInfo = steamProfileInfo.response.playerInfo;
          var bansResponse = await this.bans(
            playerInfo.truckersMPProfileInfo.id
          );
          playerInfo.bans = bansResponse.response;
          // playerInfo.onlineStatus = await
          // truckyApi.isOnline(playerInfo.truckersMPProfileInfo.id);
          playerInfo.found = true;
        }

        break;
    }

    return playerInfo;
  }

  /**
   * Get TruckersMP traffic data from traffic.krashnz.com
   *
   * @param {string} server TruckersMP Server short name
   * @param {string} game Can be "ETS2" or "ATS"
   * @async
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   */
  async traffic(server, game) {
    var response = await this.executeRequest(
      "/v2/traffic?server=" + server + "&game=" + game
    );
    return response;
  }

  async topTraffic(server, game) {
    var response = await this.executeRequest(
      "/v2/traffic/top?server=" + server + "&game=" + game
    );
    return response;
  }

  /**
   * Get TruckersMP get traffic servers data from traffic.krashnz.com
   *
   * @returns {Promise<TruckyAPIResponse>}
   * @memberof TruckyAPIClient
   * @async
   */
  async traffic_servers() {
    var response = await this.executeRequest("/v2/traffic/servers?addAts=true");
    return response;
  }

  async wot_gallery_random() {
    var response = await this.executeRequest("/v2/wot/gallery/random");
    return response;
  }

  async wot_gallery_editorspick(page) {
    var response = await this.executeRequest(
      "/v2/wot/gallery/editorspick?page=" + page
    );
    return response;
  }

  async wot_gallery_bestrated(page) {
    var response = await this.executeRequest(
      "/v2/wot/gallery/bestrated?page=" + page
    );
    return response;
  }

  async wot_gallery_mostviewed(page) {
    var response = await this.executeRequest(
      "/v2/wot/gallery/mostviewed?page=" + page
    );
    return response;
  }

  async wot_gallery_newest(page) {
    var response = await this.executeRequest(
      "/v2/wot/gallery/newest?page=" + page
    );
    return response;
  }

  async streams_twitch_ets2() {
    var response = await this.executeRequest("/v2/streams/twitch/ets2");
    return response;
  }

  async streams_twitch_ats() {
    var response = await this.executeRequest("/v2/streams/twitch/ats");
    return response;
  }

  async ets2_cities() {
    var response = await this.executeRequest("/v2/map/cities/ets2");
    return response;
  }

  async ats_cities() {
    var response = await this.executeRequest("/v2/map/cities/ats");
    return response;
  }

  async cities_all() {
    var response = await this.executeRequest("/v2/map/cities/all");
    return response;
  }

  async map_servers() {
    var response = await this.executeRequest("/v2/map/servers");
    return response;
  }

  async vtlog_userInfo(id, idType) {
    var response = await this.executeRequest(
      "/v1/vtlog/user/info?id=" + id + "&idType=" + idType
    );
    return response;
  }

  async vtlog_userJobs(id, idType) {
    var response = await this.executeRequest(
      "/v1/vtlog/user/jobs?id=" + id + "&idType=" + idType
    );
    return response;
  }

  async vtlog_companyInfo(id) {
    var response = await this.executeRequest("/v1/vtlog/company/info?id=" + id);
    return response;
  }

  async vtlog_companyMembers(id) {
    var response = await this.executeRequest(
      "/v1/vtlog/company/members?id=" + id
    );
    return response;
  }

  async partners() {
    var response = await this.executeRequest("/v2/trucky/partners");
    return response;
  }

  async searchPlayerTMP(query) {
    var response = await this.executeRequest(
      "/v2/truckersmp/searchPlayer?query=" + query
    );
    return response;
  }

  async updateSettingsAfterLogin(newSettings, steamAuth) {
    newSettings.steamUser = {
      steamID: steamAuth.user.id,
      steamDisplayName: steamAuth.user.displayName,
      avatar: steamAuth.user._json.avatarfull
    };

    console.log("Update settings after login");

    console.log("Getting TruckersMP profile");

    await this.getPlayerSummaries(steamAuth.user.id);

    var truckersMPProfileResponse = await this.player(steamAuth.user.id);
    if (!truckersMPProfileResponse.response.error) {
      newSettings.truckersMPUser = truckersMPProfileResponse.response.response;
    }

    console.log("Getting VTLog profile");

    var vtlogProfileResponse = await this.vtlog_userInfo(
      steamAuth.user.id,
      "steam"
    );

    if (vtlogProfileResponse.response && vtlogProfileResponse.status == "OK") {
      newSettings.vtLogUser = vtlogProfileResponse.response;
    }

    console.log("Getting token");

    var tokenResponse = await this.getToken(steamAuth.user.id);

    if (tokenResponse.response.success) {
      newSettings.token = tokenResponse.response.token;
      this.token = tokenResponse.response.token;
    }

    return newSettings;
  }

  async getTranslations() {
    var response = await this.executeRequest(
      "/v2/trucky/localizations/translations"
    );
    return response;
  }

  async getLanguages() {
    var response = await this.executeRequest(
      "/v2/trucky/localizations/languages"
    );
    return response;
  }

  async createAnnouncement() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/create",
      "POST",
      {},
      true
    );
    return response;
  }

  async getValidAnnouncements() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/list",
      "POST",
      {},
      true
    );
    return response;
  }

  async getValidAnnouncementsCount() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/count",
      "POST",
      {},
      true
    );
    return response;
  }

  async updateAnnouncementProperty(id, prop, action) {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/updateProperty",
      "POST",
      { id: id, prop: prop, action: action },
      true
    );
    return response;
  }

  async deleteAnnouncement(id) {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/delete",
      "POST",
      { id: id },
      true
    );
    return response;
  }

  async getMyProfile() {
    var response = await this.executeRequest(
      "/webapp/profile",
      "POST",
      {},
      true
    );
    return response;
  }

  async createAnnouncement() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/create",
      "POST",
      {},
      true
    );
    return response;
  }

  async getValidAnnouncements() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/list",
      "POST",
      {},
      true
    );
    return response;
  }

  async getValidAnnouncementsCount() {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/count",
      "POST",
      {},
      true
    );
    return response;
  }

  async updateAnnouncementProperty(id, prop, action) {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/updateProperty",
      "POST",
      { id: id, prop: prop, action: action },
      true
    );
    return response;
  }

  async deleteAnnouncement(id) {
    var response = await this.executeRequest(
      "/v2/auth/playerAnnounce/delete",
      "POST",
      { id: id },
      true
    );
    return response;
  }

  async sendReview(data) {
    var response = await this.executeRequest(
      "/v2/auth/rating/add",
      "POST",
      data,
      true
    );
    return response;
  }

  async getCompletePlayer(query) {
    var response = await this.executeRequest(
      "/v2/trucky/player?getRating=true&getOnline=false&query=" + query
    );
    return response;
  }

  async getReviewsByAuthor() {
    var response = await this.executeRequest(
      "/v2/auth/rating/listByAuthor",
      "POST",
      {},
      true
    );
    return response;
  }

  async deleteReview(id) {
    var response = await this.executeRequest(
      "/v2/auth/rating/delete",
      "POST",
      { id: id },
      true
    );
    return response;
  }

  async createEvent(data) {
    var response = await this.executeRequest(
      "/v2/auth/events/create",
      "POST",
      data,
      true
    );
    return response;
  }

  async updateEvent(data) {
    var response = await this.executeRequest(
      "/v2/auth/events/update",
      "POST",
      data,
      true
    );
    return response;
  }

  async getEventDetail(id) {
    var response = await this.executeRequest(
      "/v2/events/detail?eventID=" + id,
      "GET"
    );
    return response;
  }

  async updateEventParticipant(id, prop, action) {
    var response = await this.executeRequest(
      "/v2/auth/events/updateParticipants",
      "POST",
      { eventID: id, prop: prop, action: action },
      true
    );
    return response;
  }

  async getMyEvents() {
    var response = await this.executeRequest(
      "/v2/auth/events/getByAuthor",
      "POST",
      {},
      true
    );
    return response;
  }

  async deleteEvent(id) {
    var response = await this.executeRequest(
      "/v2/auth/events/delete",
      "POST",
      { eventID: id },
      true
    );
    return response;
  }

  async updateMapLocation(data) {
    var response = await this.executeRequest(
      "/v2/map/locations/update",
      "POST",
      data
    );
    return response;
  }

  async updateMapCountry(data) {
    var response = await this.executeRequest(
      "/v2/map/countries/update",
      "POST",
      data
    );
    return response;
  }

  async updateMapArea(data) {
    var response = await this.executeRequest(
      "/v2/map/areas/update",
      "POST",
      data
    );
    return response;
  }

  async getCountries(game, version, mod = '') {
    var response = await this.executeRequest(
      "/v2/map/countries?game=" + game + "&version=" + version + "&mod=" + mod,
      "GET"
    );
    return response;
  }

  async getAllLayers(game, version, filters, mod = '') {
    var response = await this.executeRequest(
      "/v2/map/layers?game=" + game + "&version=" + version + "&filters=" + filters.join(',') + "&mod=" + mod,
      "GET"
    );
    return response;
  }

  async deleteMapLocation(id) {
    var response = await this.executeRequest(
      "/v2/map/locations/delete",
      "POST",
      { id: id }
    );
    return response;
  }

  async deleteMapCountry(id) {
    var response = await this.executeRequest(
      "/v2/map/countries/delete",
      "POST",
      { id: id }
    );
    return response;
  }

  async deleteMapArea(id) {
    var response = await this.executeRequest("/v2/map/areas/delete", "POST", {
      id: id
    });
    return response;
  }

  async getDLCs(game) {
    var response = await this.executeRequest(
      "/v2/map/dlcs?game=" + game,
      "GET"
    );
    return response;
  }

  async getAllPOIs(game, version) {
    var response = await this.executeRequest(
      "/v3/map/pois?game=" + game + "&version=" + version,
      "GET"
    );
    return response;
  }

  async getAllProcessesList() {
    var response = await this.executeRequest(
      "/v2/auth/admin/pm2/list",
      "GET",
      {},
      true
    );
    return response;
  }

  async getSysInfo() {
    var response = await this.executeRequest(
      "/v2/auth/admin/sysinfo",
      "GET",
      {},
      true
    );
    return response;
  }

  async getUsers() {
    var response = await this.executeRequest(
      "/v2/auth/admin/users/list",
      "GET",
      {},
      true
    );
    return response;
  }

  async getDiscordMembersWithAdminAccess() {
    var response = await this.executeRequest(
      "/v2/auth/admin/users/discordMembers",
      "GET",
      {},
      true
    );
    return response;
  }

  async saveAdminUser(data) {
    var response = await this.executeRequest(
      "/v2/auth/admin/users/update",
      "POST",
      data,
      true
    );
    return response;
  }

  async saveRealOpsMessage(data) {
    var response = await this.executeRequest(
      "/v2/auth/admin/realOps/update",
      "FORM",
      data,
      true
    );
    return response;
  }

  async deleteAllRealOpsMessages()
  {
    var response = await this.executeRequest(
      "/v2/auth/admin/realOps/purge",
      "POST",
      {},
      true
    );
    return response;    
  }

  async getRealOpsMessages()
  {
    var response = await this.executeRequest(
      "/v2/trucky/realOps/messages",
      "GET",
      {},
      false
    );
    return response;        
  }
}

/**
 * Trucky API client response prototype
 * @property {object} response
 * @typedef {object} TruckyAPIResponse
 * @class
 */
var TruckyAPIResponse = {
  response: {}
};

export default TruckyAPIClient;
