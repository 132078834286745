import React from "react";
import { Table, Form, FormGroup, Input, Label } from 'reactstrap';
import TruckyAPIClient from '../../services/TruckyAPIClient';
import Button from '../../components/CustomButtons/Button';
import Roles from '../../foundation/roles';

class Users extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.api = new TruckyAPIClient();

        this.rolesSelect = React.createRef();

        this.state = {
            currentUser: null,
            action: 'list',
            users: [],
            discordMembers: []
        };
    }   

    componentDidMount()
    {
        this.getUsers();
    }

    getUsers()
    {
        this.api.getDiscordMembersWithAdminAccess().then( (data) => {
            
            this.setState({ discordMembers: data });

        });
    }

    save()
    {
        let select = this.rolesSelect.current;
        console.log(select);
        debugger;
        let values = [].filter.call(select.options, o => o.selected).map(o => o.value);

        var data = {
            discordUserID: this.state.currentUser.user.id,
            username: this.state.currentUser.user.username,
            roles: values
        };

        this.api.saveAdminUser(data).then( () => {

            this.getUsers();

            this.setState({ currentUser: null, action: 'list' });
        });
    }

    render()
    {
        return (<div>
            {this.state.action == 'list' && this.state.discordMembers.length > 0 &&
            <Table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Roles</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.discordMembers.map( (m) => {
                        return (
                            <tr>
                                <td>{m.user.username}</td>
                                <td>{m.adminRoles.join(', ')}</td>
                                <td><Button color="primary" onClick={() => this.setState({ currentUser: m, action: 'edit' })}>Edit</Button></td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            }
            {this.state.action != 'list' &&
                <Form>
                    <FormGroup>
                        <Label for="username">Username</Label>
                        <Input type="username" name="username" id="username" value={this.state.currentUser.user.username} readOnly />
                    </FormGroup>
                    <FormGroup>
                        <Label for="roles">Admin Roles</Label>
                        <select className="form-control" ref={this.rolesSelect} type="select" multiple name="roles" id="roles">
                            {Object.keys(Roles).map( (r) => {
                                return (
                                    <option value={Roles[r].key} selected={this.state.currentUser.adminRoles.includes(Roles[r].key)}>{Roles[r].label}</option>
                                );
                            })}
                        </select>
                    </FormGroup>
                    <FormGroup className="float-right">
                        <Button color="primary" onClick={() => this.save()}>Save</Button>                        
                    </FormGroup>

                </Form>
            }
        </div>)
    }
}

export default Users;