import TruckyApiClient from "../services/TruckyAPIClient";
import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.api = new TruckyApiClient();
    this.state = {
      loading: true,
      isAuthenticated: false
    };
  }

  componentDidMount() {
    this.api.checkAuth().then(data => {
      if (data.response.success) {
        this.setState({
          loading: false,
          isAuthenticated: true
        });
      } else {
        this.setState({
          loading: false,
          isAuthenticated: false
        });
      }
    });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.state.isAuthenticated ? (
            <Component {...props} />
          ) : this.state.loading ? (
            <div>LOADING</div>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: this.props.location } }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
