const fetch = require('node-fetch');
const { default: config } = require('../config');

class SocketClient {

    static async executeRequest(url, data) {
        return fetch(`${config.SOCKET_IO_API_URL}/${url}`,
            {
                method: 'POST', body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json"
                }
            });
    }

    static async sendMessage(namespace, data) {
        return this.executeRequest(`message/namespaces/${namespace}`, data);
    }

    static async sendMessageToRoom(namespace, room, data) {
        return this.executeRequest(`message/namespaces/${namespace}/rooms/${room}`, data);
    }
}

export default SocketClient;