import React from "react";
import { connect } from "react-redux";
import { globalMapStateToProps } from "../../redux/reducers";
import { globalMapDispatchToProps } from "../../redux/actions";
import L from "leaflet";
import TruckyApiClient from "../../services/TruckyAPIClient";
import HeatmapOverlay from "heatmap.js/plugins/leaflet-heatmap";
import Button from "../../components/CustomButtons/Button.jsx";
import {
  Input,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Button as ReactStrapButton,
  Alert,
} from "reactstrap";
const uuidv4 = require("uuid/v4");
require("leaflet-draw");
require("leaflet-fullscreen");
require("../../../node_modules/leaflet-draw/dist/leaflet.draw.css");
require("leaflet-rotatedmarker");
require("linqjs");

var types = [
  {
    key: "city",
    label: "City",
  },
  {
    key: "country",
    label: "Country",
  },
  {
    key: "port",
    label: "Port",
  },
  {
    key: "intersection",
    label: "Intersection",
  },
  {
    key: "road",
    label: "Road",
  },
  {
    key: "pointOfInterest",
    label: "Point of Interest",
  },
  {
    key: "toll",
    label: "Toll Gate",
  },
  {
    key: "garage",
    label: "Garage",
  },
  {
    key: "hotel",
    label: "Hotel",
  },
  {
    key: "service",
    label: "Service Station",
  },
  {
    key: "truckdealer",
    label: "Truck Dealer",
  },
  {
    key: "weigthstation",
    label: "Weight Station",
  },
  {
    key: "fuel",
    label: "Fuel Station",
  },
  {
    key: "busstation",
    label: "Bus Station",
  },
  {
    key: "railcross",
    label: "Rail Cross",
  },
  {
    key: "recruitment",
    label: "Recruitment",
  },
  {
    key: "reststop",
    label: "Rest Stop",
  },
  {
    key: "speedcamera",
    label: "Speed Camera",
  },
  {
    key: "averagespeedcheckzone",
    label: "Average Speed Check Zone",
  },
  {
    key: "photolocation",
    label: "Photo Location",
  },
  {
    key: "tightcurve",
    label: "Tight Curve",
  },
];

var mapConfiguration = {
  ets2: {
    game: "ets2",
    mod: "",
    version: "08102018",
    mapName: "mapets2",
    flagsInfos: [],
    bounds: {
      y: 131072,
      x: 131072,
    },
    maxZoom: 8,
    minZoom: 2,
    initialZoom: 5,
    showMarkersAtZoom: 5,
    followPlayerAtZoom: 7,
    cityDefaultZoom: 6,
    tileSize: 512,
    tileFormat:
      "https://tiles.truckyapp.com/ets2/05102019/{z}/{x}/{y}.png?v=" +
      new Date().getTime(),
    setViewArray: [22528 / 2, 26624 / 2],
    pointsPerPixel: 1.325928,
    multipliers: {
      x: 71292,
      y: 60402,
    },
    breakpoints: {
      uk: {
        x: -31056.8,
        y: -5832.867,
      },
    },
    calculateMapCoordinate: function (x, y) {
      var r = [
        x / this.pointsPerPixel + this.multipliers.x,
        y / this.pointsPerPixel + this.multipliers.y,
      ];

      /*if (x < this.breakpoints.uk.x && y < this.breakpoints.uk.y) {
        r = [
          r[0] / this.pointsPerPixel.uk + this.multipliers.uk.x,
          r[1] / this.pointsPerPixel.uk + this.multipliers.uk.y
        ];
      }*/
      return r;
    },
    calculateGameCoordinate: function (x, y) {
      var r = {};
      var mapBreakpoints = this.calculateMapCoordinate(
        this.breakpoints.y,
        this.breakpoints.x
      );

      /*if (x < mapBreakpoints[0] && y < mapBreakpoints[1]) {
        r.x = Math.round((x - this.multipliers.uk.y) * this.pointsPerPixel.uk);
        r.y = Math.round((y - this.multipliers.uk.x) * this.pointsPerPixel.uk);
      }*/

      r = {
        x: Math.round(
          ((r.x ? r.x : x) - this.multipliers.y) * this.pointsPerPixel
        ),
        y: Math.round(
          ((r.y ? r.y : y) - this.multipliers.x) * this.pointsPerPixel
        ),
      };

      return r;
    },
    initialLocation: {
      x: -29584,
      y: -3488,
    },
    defaultServerID: 2,
  },
  ats: {
    game: "ats",
    mod: "",
    version: "13102018",
    mapName: "mapets2",
    flagsInfos: [],
    bounds: {
      x: 131072,
      y: 131072,
    },
    maxZoom: 8,
    minZoom: 2,
    initialZoom: 5,
    showMarkersAtZoom: 5,
    followPlayerAtZoom: 7,
    cityDefaultZoom: 6,
    tileSize: 512,
    tileFormat:
      "https://tiles.truckyapp.com/ats/05102019/{z}/{x}/{y}.png?v=" +
      new Date().getTime(),
    setViewArray: [19000 / 2, 19000 / 2],
    pointsPerPixel: 1.221115,
    multipliers: {
      x: 102030,
      y: 69251,
    },
    calculateMapCoordinate: function (x, y) {
      return [
        x / this.pointsPerPixel + this.multipliers.x,
        y / this.pointsPerPixel + this.multipliers.y,
      ];
    },
    calculateGameCoordinate: function (x, y) {
      return {
        x: Math.round((x - this.multipliers.x) * this.pointsPerPixel),
        y: Math.round((y - this.multipliers.y) * this.pointsPerPixel),
      };
    },
    defaultServerID: 8,
    initialLocation: {
      x: -71524.6,
      y: 14406.3,
    },
  },
  promods: {
    game: "ets2",
    mod: "promods",
    version: "08102018",
    mapName: "mapets2",
    flagsInfos: [],
    bounds: {
      y: 131072,
      x: 131072,
    },
    maxZoom: 8,
    minZoom: 2,
    initialZoom: 5,
    showMarkersAtZoom: 5,
    followPlayerAtZoom: 7,
    cityDefaultZoom: 6,
    tileSize: 512,
    tileFormat:
      "https://tiles.truckyapp.com/promods/05102019/{z}/{x}/{y}.png?v=" +
      new Date().getTime(),
    setViewArray: [22528 / 2, 26624 / 2],
    pointsPerPixel: 2.598541,
    multipliers: {
      x: 51953,
      y: 76024,
    },
    breakpoints: {
      uk: {
        x: -31056.8,
        y: -5832.867,
      },
    },
    calculateMapCoordinate: function (x, y) {
      var r = [
        x / this.pointsPerPixel + this.multipliers.x,
        y / this.pointsPerPixel + this.multipliers.y,
      ];

      /*if (x < this.breakpoints.uk.x && y < this.breakpoints.uk.y) {
        r = [
          r[0] / this.pointsPerPixel.uk + this.multipliers.uk.x,
          r[1] / this.pointsPerPixel.uk + this.multipliers.uk.y
        ];
      }*/
      return r;
    },
    calculateGameCoordinate: function (x, y) {
      var r = {};
      var mapBreakpoints = this.calculateMapCoordinate(
        this.breakpoints.y,
        this.breakpoints.x
      );

      /*if (x < mapBreakpoints[0] && y < mapBreakpoints[1]) {
        r.x = Math.round((x - this.multipliers.uk.y) * this.pointsPerPixel.uk);
        r.y = Math.round((y - this.multipliers.uk.x) * this.pointsPerPixel.uk);
      }*/

      r = {
        x: Math.round(
          ((r.x ? r.x : x) - this.multipliers.y) * this.pointsPerPixel
        ),
        y: Math.round(
          ((r.y ? r.y : y) - this.multipliers.x) * this.pointsPerPixel
        ),
      };

      return r;
    },
    initialLocation: {
      x: -29584,
      y: -3488,
    },
    defaultServerID: 2,
  },
};

class LiveMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serverID: "0",
      game: "ets2",
      cities: [],
      showIDs: false,
      showNames: false,
      playerProfile: null,
      checkingOnlineState: false,
      drawnLayer: null,
      dataSaved: false,
      countries: [],
      allDrawnLayers: [],
      showMapElements: false,
      hideDrawnLayers: false,
      onlineFriends: [],
      dlcs: [],
      lastCity: null,
    };
  }

  componentDidMount() {
    this.initMap();
  }

  initMap() {
    this.setState({ playerProfile: null });
    this.mapConfiguration = mapConfiguration[this.state.game];
    this.debug = false;
    this.playerIDToFollow = -1;
    this.showNames = false;
    this.showIDs = false;
    this.refreshTimer = 2000;
    this.timer = null;

    //debugger;
    this.setState({ serverID: this.mapConfiguration.defaultServerID });

    this.trucks = [];
    this.flagslayer = L.layerGroup();
    this.drawedFlagsLayer = L.layerGroup();
    this.drawedMarkersLayer = L.layerGroup();
    this.playerslayer = L.layerGroup();
    this.citiesLayer = L.layerGroup();
    this.alertsLayer = L.layerGroup();
    this.alerts = [];
    this.flagsinfos = this.mapConfiguration.flagsInfos; // ['994', '554', 'United Kingdom', 'gb', '1900', '754', 'France', 'fr', '1490', '1433', 'Germany', 'de', '1420', '1020', 'Belgium', 'be', '1220', '1125', 'Netherlands', 'nl', '1600', '1130', 'Luxembourg', 'lu', '1950', '1260', 'Switzerland', 'ch', '2500', '1670', 'Italy', 'it', '1890', '1700', 'Austria', 'at', '1900', '2240', 'Hungary', 'hu', '1750', '2150', 'Slovakia', 'sk', '1590', '1760', 'Czech Republic', 'cz', '1340', '2100', 'Poland', 'pl', '800', '1450', 'Denmark', 'dk', '626', '1775', 'Sweden', 'se', '450', '1450', 'Norway', 'no'];
    const params = new URLSearchParams(document.location.search);
    this.enableDrawing = true;

    this.map = L.map(this.mapConfiguration.mapName, {
      crs: L.CRS.Simple,
      //,
      //preferCanvas: true
      //drawControl: this.enableDrawing
      //fullscreenControl: true
    });

    this.bounds = L.latLngBounds(
      this.map.unproject(
        [0, this.mapConfiguration.bounds.y],
        this.mapConfiguration.minZoom
      ),
      this.map.unproject(
        [this.mapConfiguration.bounds.x, 0],
        this.mapConfiguration.minZoom
      )
    );

    this.coloured = L.tileLayer(this.mapConfiguration.tileFormat, {
      maxZoom: this.mapConfiguration.maxZoom,
      minZoom: this.mapConfiguration.minZoom,
      tileSize: this.mapConfiguration.tileSize,
      bounds: this.bounds,
      reuseTiles: true,
    }).addTo(this.map);

    this.baseMaps = {
      //"Single Color": this.uncoloured,
      Coloured: this.coloured,
    };
    this.overlayMaps = {
      Flags: this.drawedFlagsLayer,
      Cities: this.drawedMarkersLayer,
    };

    this.map.zoomControl.setPosition("bottomright");
    this.map.setMaxBounds(
      new L.LatLngBounds(
        this.map.unproject(
          [0, this.mapConfiguration.bounds.y],
          this.mapConfiguration.minZoom
        ),
        this.map.unproject(
          [this.mapConfiguration.bounds.x, 0],
          this.mapConfiguration.minZoom
        )
      )
    );

    this.map.setView(
      this.map.unproject(
        this.mapConfiguration.setViewArray,
        this.mapConfiguration.maxZoom
      ),
      this.mapConfiguration.minZoom
    );

    L.control.layers(this.baseMaps, this.overlayMaps).addTo(this.map);

    this.drawDrawControls();

    //this.map.fitWorld();

    this.map.on("zoom", () => {
      this.drawMarkers();
      this.adjustCitiesOpacity();
    });
    this.map.on("drag", () => {
      this.playerIDToFollow = -1;
      this.setState({ playerProfile: null });
      this.drawMarkers();
    });

    this.map.on("mousemove", (ev) => {
      this.updateDebugger(ev);
    });

    this.map.on("click", (e) => { });

    this.goToLocation(
      this.mapConfiguration.initialLocation.x,
      this.mapConfiguration.initialLocation.y,
      this.mapConfiguration.initialZoom
    );

    this.getCountries();

    this.getAllDrawnLayers();

    this.getDLCs();

    this.mapReinit = false;
  }

  updateDebugger(ev) {
    var projected = this.map.project(ev.latlng, this.map.maxZoom);
    this.setState({
      debugger: {
        currentZoom: this.map.getZoom(),
        currentMousePosition: {
          latLng: ev.latlng,
          projectedCoordinates: projected,
          gameCoordinates: this.mapConfiguration.calculateGameCoordinate(
            projected.x,
            projected.y
          ),
          gameCoordinatesReversed:
            this.mapConfiguration.calculateGameCoordinate(
              projected.y,
              projected.x
            ),
        },
      },
    });
  }

  drawDrawControls() {
    var instance = this;
    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);
    var drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
      },
    });
    this.map.addControl(drawControl);
    this.getAllDrawnLayers();

    this.map.on(L.Draw.Event.CREATED, function (event) {
      debugger;
      var layer = event.layer,
        type = event.layerType;

      layer.id = uuidv4();
      layer.realName = "";
      layer.type = "";
      layer.flagCode = "";
      layer.country = "";
      layer.dlc = "";
      layer.city = "";
      //debugger;

      if (type === "marker") {
        // Do marker specific actions
      }

      instance.drawnItems.addLayer(layer);

      var drawnLayer = {
        layerType: type,
        layer: layer,
      };

      instance.setState({ drawnLayer: drawnLayer });

      //debugger;

      //console.log(drawnLayer);
    });

    this.map.on("draw:edited", function (e) {
      var layers = e.layers;
      layers.eachLayer(function (layer) {
        //do whatever you want; most likely save back to db

        debugger;

        instance.saveDrawnLayer({
          layer: layer,
          layerType: layer.layerType,
        });
      });
    });

    this.map.on("draw:deleted", function (e) {
      var layers = e.layers;
      layers.eachLayer(function (layer) {
        //do whatever you want; most likely save back to db

        //debugger;

        var api = new TruckyApiClient();
        if (layer.layerType == "marker" && layer.type == "country")
          api.deleteMapCountry(layer.id).then(() => {
            instance.getAllDrawnLayers();
            instance.getCountries();
          });
        else if (layer.layerType == "marker" && layer.type != "country")
          api.deleteMapLocation(layer.id).then(() => {
            instance.getAllDrawnLayers();
          });
        else
          api.deleteMapArea(layer.id).then(() => {
            instance.getAllDrawnLayers();
          });
      });
    });
  }

  drawCitiesFromDrawing() {
    var markers = this.state.allDrawnLayers.where((m) => {
      return m.type != "country" && m.layerType == "marker";
    });

    this.drawedMarkersLayer.clearLayers();
    this.citiesLayer.removeFrom(this.map);

    markers.forEach((marker) => {
      var coords = this.calculateMapCoordinate(
        marker.gameCoordinates.x,
        marker.gameCoordinates.y
      );
      var m = new L.Marker(
        this.map.unproject(
          [coords[0], coords[1]],
          this.mapConfiguration.maxZoom
        ),
        {
          icon: new L.DivIcon({
            iconSize: [250, 15],
            className: "citiesnames",
            html:
              '<span class="city-name">' +
              marker.realName +
              (this.debug
                ? "(" +
                marker.gameCoordinates.x +
                ", " +
                marker.gameCoordinates.y +
                ")"
                : "") +
              "</span>",
          }),
        }
      ).addTo(this.map);
      this.drawedMarkersLayer.addLayer(m);
    });
    this.drawedMarkersLayer.addTo(this.map);
    this.adjustCitiesOpacity();
  }

  drawFlagsFromDrawing() {
    this.drawedFlagsLayer.clearLayers();
    this.state.countries.forEach(function (country) {
      var pos = this.calculateMapCoordinate(
        country.gameCoordinates.x,
        country.gameCoordinates.y
      );
      var temp = new L.Marker(
        this.map.unproject(pos, this.mapConfiguration.maxZoom),
        {
          icon: new L.DivIcon({
            className: "flags",
            html:
              '<img src="https://api.truckyapp.com/mapobjects/flags/' +
              country.flagCode.toLowerCase() +
              '.svg" ><br><span>' +
              country.realName +
              "</span>",
          }),
        }
      ).addTo(this.map);
      this.drawedFlagsLayer.addLayer(temp);
    }, this);
    this.drawedFlagsLayer.addTo(this.map);
    this.flagslayer.removeFrom(this.map);
  }

  adjustCitiesOpacity() {
    for (
      var i = 0;
      i < document.getElementsByClassName("citiesnames").length;
      i++
    ) {
      document.getElementsByClassName("citiesnames")[i].style.opacity = String(
        (this.map.getZoom() - 2) / 3
      );
    }
  }

  goToLocation(x, z, definedZoom) {
    var zoom = this.mapConfiguration.cityDefaultZoom;

    if (definedZoom) zoom = definedZoom;

    this.playerIDToFollow = -1;
    var citycoords = this.calculateMapCoordinate(x, z);
    this.map.setView(
      this.map.unproject(citycoords, this.mapConfiguration.maxZoom),
      zoom
    );
  }

  calculateMapCoordinate(x, y) {
    var r = this.mapConfiguration.calculateMapCoordinate(x, y);
    return r;
  }

  calculateGameCoordinate(x, y) {
    return this.mapConfiguration.calculateGameCoordinate(x, y);
  }

  drawMarkers() {
    var instance = this;

    //debugger;
    this.playerslayer.clearLayers();

    if (this.map.getZoom() >= this.mapConfiguration.showMarkersAtZoom) {
      if (this.trucks) {
        for (var i = 0; i < this.trucks.length; i++) {
          var truck = this.trucks[i];

          var coordtruck = this.calculateMapCoordinate(truck.X, truck.Y);

          var radius = 3;
          var weight = 1;
          var fillColor = "#158CFB";

          if (this.isPlayerFollowed(truck.MpId)) fillColor = "green";

          if (this.map.getZoom() == 6) radius = 4;
          if (this.map.getZoom() == 7) {
            radius = 6;
            weight = 2;
          }
          if (this.map.getZoom() == 8) {
            radius = 8;
            weight = 3;
          }

          var temp = new L.circleMarker(
            this.map.unproject(coordtruck, this.mapConfiguration.maxZoom),
            {
              playerID: truck.MpId,
              fill: true,
              fillOpacity: 1.0,
              fillRule: "non-zero",
              fillColor: fillColor,
              weight: weight,
              stroke: true,
              color: "#000000",
              radius: radius,
              title: truck.Name + " (" + truck.MpId + ")",
            }
          );

          //var redMarker = new L.DivIcon({ className: 'player', html: '<img src="/images/gps-device.png" />'});

          //var temp = L.marker(this.map.unproject(coordtruck, this.mapConfiguration.maxZoom), {playerID: truck.MpId, icon: redMarker, rotationAngle: truck.Heading * -270 });

          /*var temp = new L.Marker(this.map.unproject(coordtruck, this.mapConfiguration.maxZoom), {              
                        icon: new L.DivIcon({
                            className: 'player',
                            html: this.createMarker(truck)
                        }),
                        playerID: truck.MpId
                    });*/
          temp.on("click", function () {
            console.log("following " + truck.MpId);
            //debugger;
            instance.playerIDToFollow = this.options.playerID;
            instance.loadPlayer(this.options.playerID);
          });
          //if (this.map.getBounds().contains(temp.getLatLng())) {
          this.playerslayer.addLayer(temp);
          //}

          if (
            this.map.getZoom() >= this.mapConfiguration.followPlayerAtZoom ||
            this.isPlayerFollowed(truck.MpId)
          ) {
            var $iconText = "";

            if (this.state.showNames || this.isPlayerFollowed(truck.MpId))
              $iconText += truck.Name;

            if (this.state.showIDs || this.isPlayerFollowed(truck.MpId))
              $iconText += " (" + truck.MpId + ")";

            if ($iconText != "")
              temp.bindTooltip($iconText, { direction: "top" }).openTooltip();
            else temp.unbindTooltip();
          }

          if (this.isPlayerFollowed(truck.MpId)) {
            //temp.bindTooltip(truck.Name + " (" + truck.MpId + ")").openTooltip();
            this.map.setView(
              this.map.unproject(
                [coordtruck[0], coordtruck[1]],
                this.mapConfiguration.maxZoom
              ),
              this.map.getZoom()
            );
          }
        }
      }
    }
  }

  getMarkers() {
    var drawMarkers = false;
    if (
      this.map.getZoom() >= this.mapConfiguration.showMarkersAtZoom &&
      !this.enableDrawing
    ) {
      drawMarkers = true;
    }
    /*if (game == 'ets2') {
     */
    //debugger;

    var bounds = this.map.getBounds();

    var ne = bounds["_northEast"];
    var sw = bounds["_southWest"];

    var projectedNe = this.map.project(ne, this.mapConfiguration.maxZoom);
    var projectedSw = this.map.project(sw, this.mapConfiguration.maxZoom);

    //console.log(projectedNe);
    //console.log(projectedSw);
    var gameCoordNe;
    var gameCoordSw;

    //debugger;

    if (this.mapConfiguration.game == "ets2") {
      //debuggerdebugger;
      gameCoordNe = this.calculateGameCoordinate(projectedNe.y, projectedNe.x);
      gameCoordSw = this.calculateGameCoordinate(projectedSw.y, projectedSw.x);

      //`Hello ${this.props.toWhat}`
      var url = `https://tracker.ets2map.com/v3/area?x1=${gameCoordSw.y}&y1=${gameCoordSw.x}&x2=${gameCoordNe.y}&y2=${gameCoordNe.x}&server=${this.state.serverID}`;

      fetch(url).then((body) => {
        //debugger;
        body.json().then((response) => {
          this.trucks = response.Data;
          if (drawMarkers) this.drawMarkers();

          if (
            this.map.getZoom() < this.mapConfiguration.showMarkersAtZoom &&
            !this.enableDrawing
          )
            this.setHeatmapData();
          else this.clearHeatMap();
        });
      });

      if (drawMarkers) {
        var alertsUrl = `https://api.truckyapp.com/v2/map/alerts/area?x1=${gameCoordSw.y}&y1=${gameCoordSw.x}&x2=${gameCoordNe.y}&y2=${gameCoordNe.x}&server=${this.state.serverID}`;

        fetch(alertsUrl).then((body) => {
          //debugger;
          body.json().then((response) => {
            this.alerts = response.response;
            this.drawAlerts();
          });
        });
      }
    }

    if (this.mapConfiguration.game == "ats") {
      gameCoordNe = this.calculateGameCoordinate(projectedNe.x, projectedNe.y);
      gameCoordSw = this.calculateGameCoordinate(projectedSw.x, projectedSw.y);

      var url = `https://tracker.ets2map.com/v3/area?x1=${gameCoordSw.x}&y1=${gameCoordSw.y}&x2=${gameCoordNe.x}&y2=${gameCoordNe.y}&server=${this.state.serverID}`;

      fetch(url).then((body) => {
        //debugger;
        body.json().then((response) => {
          this.trucks = response.Data;
          if (drawMarkers) this.drawMarkers();

          if (
            this.map.getZoom() < this.mapConfiguration.showMarkersAtZoom &&
            !this.enableDrawing
          )
            this.setHeatmapData();
          else this.clearHeatMap();
        });
      });

      if (drawMarkers) {
        var alertsUrl = `https://api.truckyapp.com/v2/map/alerts/area?x1=${gameCoordSw.x}&y1=${gameCoordSw.y}&x2=${gameCoordNe.x}&y2=${gameCoordNe.y}&server=${this.state.serverID}`;

        fetch(alertsUrl).then((body) => {
          //debugger;
          body.json().then((response) => {
            this.alerts = response.response;
            this.drawAlerts();
          });
        });
      }
    }
  }

  changeServer(serverID, cancelPlayerToFollow) {
    //debugger;
    //this.serverID = serverID;
    if (cancelPlayerToFollow) this.playerIDToFollow = -1;

    var currentServer = this.props.mapServers.first((m) => {
      return m.id == serverID;
    });
    var game = "ets2";
    if (currentServer) {
      game = currentServer.game.toLowerCase();
    }

    var refreshMap = false;

    if (this.mapConfiguration.game != game) refreshMap = true;

    this.setState({ serverID: serverID, game: game, drawnLayer: null }, () => {
      //debugger;
      if (refreshMap) {
        this.map.off();
        this.map.remove();
        this.mapReinit = true;
        this.initMap();
      }
    });
  }

  changeGame(game) {
    this.setState({ game: game }, () => {
      this.map.off();
      this.map.remove();
      this.mapReinit = true;
      this.initMap();
    });
  }

  goToCity(id) {
    var city = this.state.allDrawnLayers.first((m) => {
      return m._id == id;
    });

    if (city) {
      this.goToLocation(city.gameCoordinates.x, city.gameCoordinates.y);
    }
  }

  changeMapSettings(value, type) {
    if (type == "ids") {
      this.setState({ showIDs: value });
    }

    if (type == "names") {
      this.setState({ showNames: value });
    }

    this.drawMarkers();
  }

  saveCurrentDrawnLayer() {
    this.saveDrawnLayer(this.state.drawnLayer);
  }

  saveDrawnLayer(layer) {
    var api = new TruckyApiClient();

    var instance = this;

    this.setState({ dataSaved: false });

    debugger;

    if (
      document.getElementById("dlc") != undefined &&
      document.getElementById("dlc").value == ""
    ) {
      alert("Please select a dlc");
      return;
    }

    if (
      document.getElementById("country") != undefined &&
      document.getElementById("country").value == ""
    ) {
      alert("Please select a country");
      return;
    }

    if (
      document.getElementById("realName") != undefined &&
      document.getElementById("realName").value == ""
    ) {
      alert("Please insert a name");
      return;
    }

    if (
      document.getElementById("in_game_id") != undefined &&
      document.getElementById("in_game_id").value == ""
    ) {
      alert("Please insert an In-Game ID");
      return;
    }

    var data = {
      layerID: layer.layer.id,
      layerType: layer.layerType,
      game: this.mapConfiguration.game,
      type: document.getElementById("type")
        ? document.getElementById("type").value
        : layer.type,
      realName: document.getElementById("realName")
        ? document.getElementById("realName").value
        : layer.realName,
      mapVersion: this.mapConfiguration.version,
      flagCode: document.getElementById("flagCode")
        ? document.getElementById("flagCode").value
        : layer
          ? layer.flagCode
          : undefined,
      country:
        document.getElementById("country") != undefined
          ? document.getElementById("country").value
          : layer && layer.country != undefined
            ? layer.country._id
            : undefined,
      dlc:
        document.getElementById("dlc") != undefined
          ? document.getElementById("dlc").value
          : layer && layer.dlc != undefined
            ? layer.dlc._id
            : undefined,
      city:
        document.getElementById("city") != undefined
          ? document.getElementById("city").value
          : layer && layer.city != undefined
            ? layer.city._id
            : undefined,
      mod: this.mapConfiguration.mod,
      in_game_id: document.getElementById("in_game_id")
        ? document.getElementById("in_game_id").value
        : layer.in_game_id,
    };

    if (
      (data.realName == undefined ||
        data.realName == null ||
        data.realName == "") &&
      data.type != undefined
    )
      data.realName = types.first((m) => m.key == data.type).label;

    if (layer.layerType == "marker") {
      //debugger;
      data.mapCoordinates = {
        x: layer.layer._latlng.lat,
        y: layer.layer._latlng.lng,
      };

      var projectedCoordinates = this.map.project(
        layer.layer._latlng,
        this.mapConfiguration.maxZoom
      );
      var gameCoordinates = this.mapConfiguration.calculateGameCoordinate(
        this.mapConfiguration.game == "ats"
          ? projectedCoordinates.x
          : projectedCoordinates.y,
        this.mapConfiguration.game == "ats"
          ? projectedCoordinates.y
          : projectedCoordinates.x
      );
      data.gameCoordinates = {
        x:
          this.mapConfiguration.game == "ats"
            ? gameCoordinates.x
            : gameCoordinates.y,
        y:
          this.mapConfiguration.game == "ats"
            ? gameCoordinates.y
            : gameCoordinates.x,
      };

      if (data.type == "country") {
        if (data.flagCode == "") {
          alert("Please insert a flag code for this country");
          return;
        }

        api.updateMapCountry(data).then(() => {
          this.setState({ dataSaved: true });

          instance.getAllDrawnLayers();
          instance.getCountries();
        });
      } else {
        api.updateMapLocation(data).then(() => {
          this.setState({ dataSaved: true });

          instance.getAllDrawnLayers();
        });
      }
    }

    if (layer.layerType == "polygon") {
      debugger;
      data.mapCoordinates = layer.layer._latlngs[0];
      data.gameCoordinates = layer.layer._latlngs[0].map((l) => {
        var projectedCoordinates = this.map.project(
          l,
          this.mapConfiguration.maxZoom
        );
        var gameCoordinates = this.mapConfiguration.calculateGameCoordinate(
          this.mapConfiguration.game == "ats"
            ? projectedCoordinates.x
            : projectedCoordinates.y,
          this.mapConfiguration.game == "ats"
            ? projectedCoordinates.y
            : projectedCoordinates.x
        );
        return {
          x:
            this.mapConfiguration.game == "ats"
              ? gameCoordinates.x
              : gameCoordinates.y,
          y:
            this.mapConfiguration.game == "ats"
              ? gameCoordinates.y
              : gameCoordinates.x,
        };
      });

      api.updateMapArea(data).then(() => {
        this.setState({ dataSaved: true });

        instance.getAllDrawnLayers();
      });
    }

    if (layer.layerType == "rectangle") {
      debugger;

      var bounds = layer.layer.getBounds();

      var ne = bounds["_northEast"];
      var sw = bounds["_southWest"];

      var projectedNe = this.map.project(ne, this.mapConfiguration.maxZoom);
      var projectedSw = this.map.project(sw, this.mapConfiguration.maxZoom);

      data.mapCoordinates = {};
      data.gameCoordinates = {};

      data.mapCoordinates = {
        ne: {
          x: ne.lat,
          y: ne.lng,
        },
        sw: {
          x: sw.lat,
          y: sw.lng,
        },
      };

      var gameCoordNe, gameCoordSw;

      if (this.mapConfiguration.game == "ets2") {
        gameCoordNe = this.calculateGameCoordinate(
          projectedNe.y,
          projectedNe.x
        );
        gameCoordSw = this.calculateGameCoordinate(
          projectedSw.y,
          projectedSw.x
        );

        data.gameCoordinates = {
          ne: {
            x: gameCoordSw.y,
            y: gameCoordSw.x,
          },
          sw: {
            x: gameCoordNe.y,
            y: gameCoordNe.x,
          },
        };
      } else {
        gameCoordNe = this.calculateGameCoordinate(
          projectedNe.x,
          projectedNe.y
        );
        gameCoordSw = this.calculateGameCoordinate(
          projectedSw.x,
          projectedSw.y
        );

        data.gameCoordinates = {
          ne: {
            x: gameCoordNe.x,
            y: gameCoordNe.y,
          },
          sw: {
            x: gameCoordSw.x,
            y: gameCoordSw.y,
          },
        };
      }

      api.updateMapArea(data).then(() => {
        this.setState({ dataSaved: true });

        instance.getAllDrawnLayers();
      });
    }

    // reset selected layer
    this.setState({ drawnLayer: null });

    if (document.getElementById("in_game_id"))
      document.getElementById("in_game_id").value = "";
    if (document.getElementById("realName"))
      document.getElementById("realName").value = "";
    if (document.getElementById("dlc"))
      document.getElementById("dlc").value = "";
    if (document.getElementById("country"))
      document.getElementById("country").value = "";
    if (document.getElementById("layerType"))
      document.getElementById("layerType").value = "";
    if (document.getElementById("city"))
      document.getElementById("city").value = "";
    if (document.getElementById("flagCode"))
      document.getElementById("flagCode").value = "";
  }

  convertAllMarkersToAreas() {
    var layers = this.state.allDrawnLayers.where((m) => {
      return m.layerType == "marker" && m.type != "country";
    });

    layers.forEach((m) => {
      this.convertMarkerToArea(m);
    }, this);
  }

  convertMarkerToArea(layer) {
    debugger;

    var newLayer = {
      type: layer.type,
      layerType: "rectangle",
      realName: layer.realName,
      country: layer.country ? layer.country._id : undefined,
      dlc: layer.dlc ? layer.dlc._id : undefined,
      gameCoordinates: {},
      layerID: uuidv4(),
      game: layer.game,
      mapVersion: layer.mapVersion,
    };

    newLayer.gameCoordinates = {
      ne: {
        x: layer.gameCoordinates.x - 600,
        y: layer.gameCoordinates.y - 500,
      },
      sw: {
        x: layer.gameCoordinates.x + 600,
        y: layer.gameCoordinates.y + 500,
      },
    };

    var api = new TruckyApiClient();
    api.updateMapArea(newLayer).then(() => { });
  }

  async getCountries() {
    return new Promise((resolve, reject) => {
      var api = new TruckyApiClient();
      api
        .getCountries(
          this.mapConfiguration.game,
          this.mapConfiguration.version,
          this.mapConfiguration.mod
        )
        .then((data) => {
          this.setState({ countries: data.response }, () => resolve());

          //if (this.enableDrawing)
          this.drawFlagsFromDrawing();
        });
    });
  }

  fillCitiesDropdown() {
    var groupedCities = this.state.allDrawnLayers
      .where((c) => {
        return c.layerType == "marker" && c.country != undefined;
      })
      .groupBy((c) => {
        return c.country && c.country != null && c.country.realName;
      });

    return groupedCities.map((c) => {
      return (
        <optgroup label={c.key}>
          {c.map((m) => {
            return <option value={m._id}>{m.realName}</option>;
          })}
          ;
        </optgroup>
      );
    });
    //debugger;
  }

  async getAllDrawnLayers() {
    var instance = this;

    var api = new TruckyApiClient();
    api
      .getAllLayers(
        this.mapConfiguration.game,
        this.mapConfiguration.version,
        types.map((m) => m.key),
        this.mapConfiguration.mod
      )
      .then((data) => {
        this.setState({ allDrawnLayers: data.response });
        this.drawCitiesFromDrawing();

        if (this.enableDrawing) {
          this.drawnItems.clearLayers();

          data.response.forEach((p) => {
            var layer;

            if (p.layerType == "marker") {
              //layer = L.marker([p.mapCoordinates.x, p.mapCoordinates.y]);
              var coords = this.mapConfiguration.calculateMapCoordinate(
                p.gameCoordinates.x,
                p.gameCoordinates.y
              );
              layer = L.marker(
                this.map.unproject(coords, this.mapConfiguration.maxZoom)
              );
            }

            if (p.layerType == "rectangle") {
              layer = L.rectangle([
                this.map.unproject(
                  this.mapConfiguration.calculateMapCoordinate(
                    p.gameCoordinates.ne.x,
                    p.gameCoordinates.ne.y
                  ),
                  this.mapConfiguration.maxZoom
                ),
                this.map.unproject(
                  this.mapConfiguration.calculateMapCoordinate(
                    p.gameCoordinates.sw.x,
                    p.gameCoordinates.sw.y
                  ),
                  this.mapConfiguration.maxZoom
                ),
              ]);
            }

            if (p.layerType == "polygon") {
              layer = L.polygon(
                p.gameCoordinates.map((xy) => {
                  return this.map.unproject(
                    this.mapConfiguration.calculateMapCoordinate(xy.x, xy.y),
                    this.mapConfiguration.maxZoom
                  );
                })
              );
            }

            layer.id = p.layerID;
            layer.realName = p.realName;
            layer.type = p.type;
            layer.country = p.country ? p.country._id : "";
            layer.layerType = p.layerType;
            layer.flagCode = p.flagCode;
            layer.dlc = p.dlc ? p.dlc._id : "";
            layer.mod = p.mod;
            layer.in_game_id = p.in_game_id;
            //layer.editing.enable();

            layer.on("click", (event) => {
              //debugger;
              instance.setState({
                drawnLayer: {
                  layerType: event.target.layerType,
                  layer: event.target,
                },
              });
            });

            this.drawnItems.addLayer(layer);

            layer.bindPopup(p.realName);
          });
        }
      });
  }

  viewDrawnLayer(id) {
    //debugger;
    var layer = this.state.allDrawnLayers.first((m) => {
      return m.layerID == id;
    });
    var coords;
    if (layer) {
      if (layer.layerType == "marker") {
        coords = this.mapConfiguration.calculateMapCoordinate(
          layer.gameCoordinates.x,
          layer.gameCoordinates.y
        );
      } else {
        coords = this.mapConfiguration.calculateMapCoordinate(
          layer.gameCoordinates.x,
          layer.gameCoordinates.y
        );
      }

      //this.setState({ drawnLayer: { layerType: layer.layerType, layer: layer } } );

      this.map.eachLayer((l) => {
        if (l.id == id) l.fire("click");
      });

      var zoom = 6;
      if (layer.type == "country") zoom = 4;

      this.map.setView(
        this.map.unproject(
          [coords[0], coords[1]],
          this.mapConfiguration.maxZoom
        ),
        zoom
      );
    }
  }

  updateDrawnLayerProp(prop, value) {
    this.state.drawnLayer.layer[prop] = value;

    if (prop == "city") this.setState({ lastCity: value });

    this.setState({ drawnLayer: this.state.drawnLayer });
  }

  hideDrawnLayers(checked) {
    if (checked) this.map.removeLayer(this.drawnItems);
    else this.map.addLayer(this.drawnItems);

    this.setState({ hideDrawnLayers: checked });
  }

  getDLCs() {
    var api = new TruckyApiClient();
    api.getDLCs(this.mapConfiguration.game).then((data) => {
      this.setState({ dlcs: data.response });
    });
  }

  convertCities() {
    var api = new TruckyApiClient();
    this.state.cities.forEach((c) => {
      //debugger;

      var alreadyExistsCity = this.state.allDrawnLayers.first((m) => {
        return (
          m.layerType == "marker" &&
          m.type == "city" &&
          m.realName == c.realName
        );
      });

      if (alreadyExistsCity == null) {
        //debugger;

        var coords = this.calculateMapCoordinate(c.x, c.z);

        var mapCoordinates = this.map.unproject(
          [coords[0], coords[1]],
          this.mapConfiguration.maxZoom
        );

        var layer = {
          layerID: c._id,
          type: "city",
          layerType: "marker",
          game: c.game.toLowerCase(),
          mapVersion: this.mapConfiguration.version,
          gameCoordinates: {
            x: c.x,
            y: c.z,
          },
          mapCoordinates: {
            x: mapCoordinates.lat,
            y: mapCoordinates.lng,
          },
          realName: c.realName,
        };

        api.updateMapLocation(layer);
      }
    }, this);
  }

  goFullScreen() {
    this.map.toggleFullscreen();
  }

  showFullFields() {
    return (
      this.state.drawnLayer.layer.type == "country" ||
      this.state.drawnLayer.layer.type == "city" ||
      this.state.drawnLayer.layer.type == "port" ||
      this.state.drawnLayer.layer.type == "intersection" ||
      this.state.drawnLayer.layer.type == "road"
    );
  }

  render() {
    return (
      <div>
        <Button
          color="primary"
          onClick={() => {
            this.changeGame("ets2");
          }}
        >
          ETS2
        </Button>

        <Button
          color="primary"
          onClick={() => {
            this.changeGame("ats");
          }}
        >
          ATS
        </Button>

        <Button
          color="primary"
          onClick={() => {
            this.changeGame("promods");
          }}
        >
          Promods
        </Button>

        <div style={{ height: "800px" }} id="mapets2">
          <div className="mb-5 draw-control-alllayers">
            <Form>
              <FormGroup>
                <Label for="allLayers">All layers</Label>
                <Input
                  onChange={(e) => this.viewDrawnLayer(e.target.value)}
                  type="select"
                  name="allLayers"
                  id="allLayers"
                >
                  <option value="" />
                  {this.state.allDrawnLayers.map((l) => {
                    return (
                      <option value={l.layerID}>
                        {l.layerType} - {l.realName} ({l.type}){" "}
                        {l.dlc == undefined ? "NODLC" : ""}{" "}
                        {l.type != "country" && l.country == undefined
                          ? "NOCOUNTRY"
                          : ""}{" "}
                        {l.type == "city" &&
                          l.layerType == "marker" &&
                          !l.in_game_id
                          ? "NO_IN_GAME_ID"
                          : ""}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={this.state.showMapElements}
                    onChange={(e) =>
                      this.setState({ showMapElements: e.target.checked })
                    }
                  />{" "}
                  Show map elements from drawn layers
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={this.state.hideDrawnLayers}
                    onChange={(e) => this.hideDrawnLayers(e.target.checked)}
                  />{" "}
                  Hide drawn layers
                </Label>
              </FormGroup>
            </Form>
            {this.state.debugger && this.state.debugger != undefined && (
              <div className="mt-5">
                Zoom: {this.state.debugger.currentZoom}
                <br />
                Position: lat{" "}
                {this.state.debugger.currentMousePosition.latLng.lat} - lng:{" "}
                {this.state.debugger.currentMousePosition.latLng.lng}
                <br />
                Projected: lat{" "}
                {
                  this.state.debugger.currentMousePosition.projectedCoordinates
                    .x
                }{" "}
                - lng:{" "}
                {
                  this.state.debugger.currentMousePosition.projectedCoordinates
                    .y
                }
                <br />
                Game coords: x{" "}
                {this.state.debugger.currentMousePosition.gameCoordinates.x} -
                y: {this.state.debugger.currentMousePosition.gameCoordinates.y}
                <br />
                Reversed game coords: x:{" "}
                {
                  this.state.debugger.currentMousePosition
                    .gameCoordinatesReversed.y
                }{" "}
                - y:{" "}
                {
                  this.state.debugger.currentMousePosition
                    .gameCoordinatesReversed.x
                }
              </div>
            )}
          </div>
          {this.enableDrawing && this.state.drawnLayer && (
            <div>
              <div className="draw-control-form">
                <Form>
                  <FormGroup>
                    <Label for="layerType">Layer Type</Label>
                    <Input
                      value={this.state.drawnLayer.layerType}
                      type="select"
                      name="layerType"
                      id="layerType"
                      disabled
                    >
                      <option value="rectangle">Area</option>
                      <option value="polygon">Polygon</option>
                      <option value="marker">Marker</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <Input
                      type="select"
                      value={this.state.drawnLayer.layer.type}
                      name="type"
                      id="type"
                      onChange={(e) =>
                        this.updateDrawnLayerProp("type", e.target.value)
                      }
                    >
                      <option value="">Choose a type</option>
                      {types.map((m) => {
                        return <option value={m.key}>{m.label}</option>;
                      })}
                    </Input>
                  </FormGroup>
                  {this.showFullFields() && (
                    <FormGroup>
                      <Label for="realName">Name</Label>
                      <Input
                        type="text"
                        value={this.state.drawnLayer.layer.realName}
                        name="realName"
                        id="realName"
                        onChange={(e) =>
                          this.updateDrawnLayerProp("realName", e.target.value)
                        }
                      />
                    </FormGroup>
                  )}
                  {this.state.drawnLayer.layer.type == "city" &&
                    this.state.drawnLayer.layer.layerType == "marker" && (
                      <FormGroup>
                        <Label for="in_game_id">In-Game ID</Label>
                        <Input
                          type="text"
                          value={this.state.drawnLayer.layer.in_game_id}
                          name="in_game_id"
                          id="in_game_id"
                          onChange={(e) =>
                            this.updateDrawnLayerProp(
                              "in_game_id",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>
                    )}
                  {this.showFullFields() &&
                    this.state.drawnLayer.layer.type != "country" && (
                      <FormGroup>
                        <Label for="country">Country</Label>
                        <Input
                          type="select"
                          value={this.state.drawnLayer.layer.country}
                          name="country"
                          id="country"
                          onChange={(e) =>
                            this.updateDrawnLayerProp("country", e.target.value)
                          }
                        >
                          {this.state.countries.map((c) => {
                            return (
                              <option key={c.layerID} value={c.layerID}>
                                {c.realName}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}
                  {!this.showFullFields() && (
                    <FormGroup>
                      <Label for="country">City</Label>
                      <Input
                        type="select"
                        value={
                          this.state.drawnLayer.layer.city == ""
                            ? this.state.lastCity
                            : this.state.drawnLayer.layer.city
                        }
                        name="city"
                        id="city"
                        onChange={(e) =>
                          this.updateDrawnLayerProp("city", e.target.value)
                        }
                      >
                        <option value="">No City</option>
                        {this.state.allDrawnLayers
                          .where((m) => {
                            return (
                              (m.type == "city") & (m.layerType == "marker")
                            );
                          })
                          .map((c) => {
                            return (
                              <option key={c.layerID} value={c.layerID}>
                                {c.realName}
                              </option>
                            );
                          })}
                      </Input>
                    </FormGroup>
                  )}
                  {this.state.drawnLayer.layer.type == "country" && (
                    <FormGroup>
                      <Label for="flagCode">Flag code</Label>
                      <Input
                        type="text"
                        value={this.state.drawnLayer.layer.flagCode}
                        name="flagCode"
                        id="flagCode"
                        onChange={(e) =>
                          this.updateDrawnLayerProp("flagCode", e.target.value)
                        }
                      />
                    </FormGroup>
                  )}
                  {this.showFullFields() && (
                    <FormGroup>
                      <Label for="dlc">Map DLC</Label>
                      <Input
                        type="select"
                        value={this.state.drawnLayer.layer.dlc}
                        name="dlc"
                        id="dlc"
                        onChange={(e) =>
                          this.updateDrawnLayerProp("dlc", e.target.value)
                        }
                      >
                        <option key="" value="">
                          Select a Map DLC
                        </option>
                        {this.state.dlcs.map((c) => {
                          return (
                            <option key={c._id} value={c._id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label for="mapVersion">Map Version</Label>
                    <br />
                    {this.mapConfiguration.version}
                  </FormGroup>
                  <FormGroup>
                    <Label for="game">Game</Label>
                    <br />
                    {this.mapConfiguration.game}
                  </FormGroup>
                  <FormGroup>
                    <Label for="mod">Mod</Label>
                    <br />
                    {this.mapConfiguration.mod}
                  </FormGroup>
                  <Button
                    block
                    className="mt-3"
                    color="primary"
                    onClick={() => this.saveCurrentDrawnLayer()}
                  >
                    Save
                  </Button>
                  {this.state.dataSaved && (
                    <Alert className="mt-3" color="success">
                      Layer data updated
                    </Alert>
                  )}
                </Form>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  globalMapStateToProps,
  globalMapDispatchToProps
)(LiveMap);
