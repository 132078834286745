// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views
import DashboardPage from "../views/Dashboard/Dashboard.jsx";
import Maps from "../views/Maps/Maps.jsx";
import Users from '../views/Users/Users.jsx';
import Roles from '../foundation/roles';
import RealOpsPanel from '../views/RealOps/RealOpsPanel.jsx';

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Trucky Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    roles: []
  },
  {
    path: "/mapEditor",
    sidebarName: "Map Editor",
    navbarName: "Map Editor",
    icon: LocationOn,
    component: Maps,
    roles: [ Roles.MAP_EDITOR.key ]
  },
  
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect", roles: [] }
];

export default dashboardRoutes;
