import thunkMiddleware from 'redux-thunk';
import {
    createStore, applyMiddleware
} from 'redux';
import reducers from './reducers';
import { logger } from 'redux-logger';

export default function configureStore(initialState = {}) {
    const store = createStore(
        reducers,
        initialState,
          applyMiddleware(
                thunkMiddleware, // lets us dispatch() functions,
                logger,
                //loadingBarMiddleware()
             )

    )
    return store;
};