import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import TruckyApiClient from "./services/TruckyAPIClient";
import PrivateRoute from "./foundation/PrivateRoute";
import configureStore from "./redux/store";
import { Provider } from "react-redux";

import "./assets/css/material-dashboard-react.css";
import "./assets/css/trucky.css";

import indexRoutes from "./routes/index.jsx";
import { checkAuth } from "./redux/actions";
import config from './config';

const hist = createBrowserHistory();

const store = configureStore();
store.dispatch(checkAuth()).then(function (authenticated) {
  console.log(authenticated);

  if (authenticated) {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </Router>
        ,
      </Provider>,
      document.getElementById("root")
    );
  }
  else {
    //ReactDOM.render(<>Not authenticated</>, document.getElementById("root"));    
    window.location.href = config.AUTH_PAGE_URL;
  }
});
