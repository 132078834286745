import * as constants from './constants';
import {
    combineReducers
   } from 'redux';
/* eslint-disable */


var initialState = {
    user: null,
    isAuthenticated: false,
    mapServers: []
};

export function appReducer(state = initialState, action) {
    //console.log(state);
    //console.log(action);
    switch (action.type)
    {
        case constants.CHECK_AUTH:
            return Object.assign({}, state, {
                user: action.user,
                isAuthenticated: action.isAuthenticated
            });
        break;
        case constants.GET_MAP_SERVERS:
                //logReducer('getMapServers', 'getting new state');
                //console.log(action.map_servers);
                return Object.assign({}, state, {
                    mapServers: action.mapServers
                });
                break;
        default:
            return state;
    }
};

const reducers = combineReducers({
    appReducer
});

export const globalMapStateToProps = state => {
    return {
        user: state.appReducer.user,
        isAuthenticated: state.appReducer.isAuthenticated,
        mapServers: state.appReducer.mapServers
    }
}

export default reducers;
